<template>
  <div v-if="selectedProducto">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <productosForm
        productoSubmit="Crear Producto"
        :producto="setProductoClone(selectedProducto)"
        @processProducto="agregarProductos"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const productosForm = () => import('@/layouts/components/Productos/ProductosForm.vue')

export default {
  components: {
    BOverlay,
    productosForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('productos', ['selectedProducto']),
  },
  methods: {
    ...mapActions({ createProductos: 'productos/addProductos' }),
    setProductoClone(producto) {
      const selectedProducto = producto
      selectedProducto.codigo = null
      selectedProducto.imagen = null
      // this.cargarImg()
      return producto
    },
    agregarProductos(producto) {
      this.spinner = true
      this.createProductos(producto).then(() => {
        const errorProductos = store.state.productos
        const errorMessage = errorProductos.errorMessage.errors
        if (!errorProductos.error) {
          this.spinner = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Producto creado con éxito 👍',
              icon: 'CheckIcon',
              text: `El producto "${producto.codigo} - ${producto.nombre}" fue creado con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'productos',
          })
        } else if (errorMessage.codigo) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.codigo[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
